import React from 'react';
import TwelveDaysLogo from '../../images/12days-logo.png'
import DownArrow from '../../images/down-arrow.png'
import Activities from '../../documents/12DaysActivityBookWithoutCover.pdf'
import Answers from '../../documents/12daysAnswerSheet.pdf'
import Thought from '../../images/thought.png'
import Bubbles from '../../images/bubbles.png'
import Splat from '../../images/splat.png'
import Feet from '../../images/feet.png'
import Light from '../../images/lightbulb.png'
import Camera from '../../images/camera.png'
import Shark from '../../images/shark.png'

class TwelveDaysActivities extends React.Component {
    render(){
        return (
            <div className = "mainContent">
                <div className="contentBlock">
                    <div className="contentHeaderImage"><img src={TwelveDaysLogo} alt="12 days of Christchurch Marine Conservation" /></div>
                    <div className="contentText">
                        <p>It's been a long year but Christmas finally arrived. You've unwrapped presents, you've had your Christmas meal, you've eaten all the chocolate you can find, so what now?</p>
                        <br/>
                        <p>On December 25th, the 12 days of Christmas start and we have activities for every day to see you through to the New Year and back to school.</p>
                        <br/>
                        <p>You can join in through the website or on our social media pages, it all starts with the Activity Book.</p>
                    </div>
                    <div className="contentArrow"><img src={DownArrow} alt="" /></div>
                    <div className="contentText">
                        <p>You don't have to print it out, but you can. It's a normal page size and it doesn't have lots of photos or color so it's quick and easy to print.</p>
                        <br/>
                        <p><a href={Activities} target="_blank" rel="noopener noreferrer" className="campaignLink">Download the Activity Book without the Cover Page</a></p>
                        <br/>
                        <p><a href={Answers} target="_blank" rel="noopener noreferrer" className="campaignLink">Download the Answer Sheet</a></p>
                        <br/>
                    </div>
                    <div className="contentText">
                        <p>Every day has four activities:</p>
                        <br/>
                        <div className="contentRow">
                            <div className="contentImage"><img src={Thought} alt="" /></div>
                            <div className="contentDesc"><span className="strong">Question time</span> - a quick question for everyone to think about.</div>
                        </div>
                        <div className="contentRow">
                            <div className="contentImage"><img src={Bubbles} alt="" /></div>
                            <div className="contentDesc"><span className="strong">Fact Finders</span> - find out more about the topic of the day</div>
                        </div>
                        <div className="contentRow">
                            <div className="contentImage"><img src={Splat} alt="" /></div>
                            <div className="contentDesc"><span className="strong">Get arty</span> - things that you can make and do</div>
                        </div>
                        <div className="contentRow">
                            <div className="contentImage"><img src={Feet} alt="" /></div>
                            <div className="contentDesc"><span className="strong">Out and About</span> - if it's not raining and you can go out for a walk, these are some extra things to look at and think about.</div>
                        </div>
                        <div className="contentRow">
                            <div className="contentImage"><img src={Light} alt="" /></div>
                            <div className="contentDesc">
                                <div className="contentDescTitle">Need some ideas?</div>
                                <div className="contentDescText">
                                    <p>We know it's not always easy to think of what to do or where to look for information, so every day comes with it’s own web page right here on this website with links and information to help you out.</p>
                                    <br/>
                                    <p>We also know that not everyone can always get to a beach, so for each activity we have shared some photos from the local beaches so you can see what we are talking about and can use those to help with your activities.</p>
                                </div>
                            </div>
                        </div>
                        <div className="contentRow">
                            <div className="contentImage"><img src={Camera} alt="" /></div>
                            <div className="contentDesc">
                            <div className="contentDescTitle">Don't forget to share</div>
                                <div className="contentDescText">
                                    <p>We would love to see what you are doing with our workbook! If you can, please take some photos of your arts and crafts, your daily walks and share your fact finding with us.</p>
                                    <br/>
                                    <p>You can find us on Facebook, Instagram and Twitter as @cmarcuk</p>
                                    <br/>
                                    <p>Or, if you don't have social media, email us at hello@cmarc.org.uk and let us know if you're happy for us to share online. If you're not, that's fine, we'd still love to hear about what you're doing.</p>
                                    <br/>
                                </div>
                            </div>
                        </div>
                        <div className="contentRow">
                            <div className="contentFooterImage"><img src={Shark} alt="" /></div>
                            <div className="contentFooterText">Have fun!</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TwelveDaysActivities;