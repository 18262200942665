import React from 'react';
import {Link} from 'react-router-dom';
import Banner from '../components/constants/banner'
import Facebook from '../images/facebook-large.png';
import Instagram from '../images/instagram-large.png';
import Twitter from '../images/twitter-large.png';
import News from '../images/news.svg';
import Campaigns from '../images/campaigns.svg';
import Events from '../images/events.svg';
import Join from '../images/join.svg';

class Home extends React.Component {
    render(){
        return (
            <div className = "homeContent">
                <section className='banner' title="Banner image">
                    <Banner />
                </section>
                <section title="Main page content">
                    <div id="homequote">
                        <blockquote>The sea, the great unifier, is man's only hope. Now, as never before, the old phrase has a literal meaning: we are all in the same boat.</blockquote>
                        <cite>Jacques Yves Cousteau, Oceanographer</cite>
                    </div>
                    <hr />
                    <div id="mainContent">
                        <div>Hello! Welcome to Christchurch Marine Conservation, where we are passionate about saving the Earth and the oceans. We are definitely all in the same boat, but sometimes it feels like we are all paddling in different directions! We would like to try and organise those oars and get everyone pulling together.</div>
                        <div className="buttonLarge">
                            <a href="#news">
                                <div><img src={Join} alt="Person icon" />Come and join us</div>
                                <div>Check out our news, campaigns and social media to find out more</div>
                            </a>
                        </div>
                        <hr className="full" />
                        <div className="tiles">
                            <div className="tile" id="news">
                                <Link to ="/news">
                                    <div className="tileImage"><img src={News} alt="Newspaper icon" /></div>
                                    <div className="tileTitle">News</div>
                                    <div className="tileSpacer"></div>
                                    <div className="tileText">Read our latest news and updates</div>
                                    <div className="tileButton">Read the news</div>
                                </Link>
                            </div>
                            <div className="tile">
                                <Link to ="/campaigns">
                                    <div className="tileImage"><img src={Campaigns} alt="Loud speaker icon" /></div>
                                    <div className="tileTitle">Campaigns</div>
                                    <div className="tileSpacer"></div>
                                    <div className="tileText">See what campaigns we are running</div>
                                    <div className="tileButton">Get involved</div>
                                </Link>                                </div>
                            <div className="tile">
                                <Link to="/events">
                                    <div className="tileImage"><img src={Events} alt="Calendar icon" /></div>
                                    <div className="tileTitle">Events</div>
                                    <div className="tileSpacer"></div>
                                    <div className="tileText">Book a place at our events</div>
                                    <div className="tileButton">Book now</div>
                                </Link>
                            </div>
                        </div>
                        <hr className="full" />
                        <div className="socialcards">
                            <div className='socialcard'>
                                <a href="https://www.facebook.com/cmarcuk" rel="noopener noreferrer">
                                    <div className="socialcardImage"><img src={Facebook} alt="Facebook" /></div>
                                    <div className="socialcardTitle">Facebook</div>
                                    <div className="socialcardText">/cmarcuk</div>
                                </a>
                            </div>
                            <div className='socialcard'>
                                <a href="https://www.instagram.com/cmarcuk" rel="noopener noreferrer">
                                    <div className="socialcardImage"><img src={Instagram} alt="Instagram" /></div>
                                    <div className="socialcardTitle">Instagram</div>
                                    <div className="socialcardText">@cmarcuk</div>
                                </a>
                            </div>
                            <div className='socialcard'>
                                <a href="https://www.twitter.com/cmarcuk" rel="noopener noreferrer">
                                    <div className="socialcardImage"><img src={Twitter} alt="Twitter" /></div>
                                    <div className="socialcardTitle">Twitter</div>
                                    <div className="socialcardText">@cmarcuk</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Home;