import React from 'react';
import {Link} from 'react-router-dom';
import Banner from '../../components/constants/banner'
import TwelveDays from '../../images/12days-logo.png'

class NewsTwelveDays extends React.Component {
    render(){
        return (
            <div className = "mainContent">
                <section className='banner banneroverlay bannercrop' title="Banner image">
                    <Banner text={'News Article'} />
                </section>
                <div className="newsBlock">
                    <div className="newsItem">
                        <div className="newsTitle">12 Days of Christmas</div>
                        <div className="newsDate">Posted on: December 24, 2020</div>
                        <div className="newsText">
                            <p>We have come up with a plan to keep people occupied during the time between Christmas and New Year!</p>
                            <br/>
                            <p><Link to="/12days" className="campaignLink">Have a look at our 12 Days page and download the Activity Book</Link></p>
                            <br/><br/>
                        </div>
                        <div className="newsImage">
                            <img src={TwelveDays} alt="12 days of Christchurch Marine Conservation" />
                        </div>
                    </div>
                    <div className="newsSide">
                        <div className="newsSideHeading">Recent Posts</div>
                        <div className="newsSideItems">
                            <div className="newsSideItem"><a href="/news-12days">12 Days of Christmas</a></div>
                            <div className="newsSideItem"><a href="/news-shine">Shine a Light on Litter</a></div>
                            <div className="newsSideItem"><a href="/news-hello">Hello world!</a></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsTwelveDays;