import React from 'react';
import {Link} from 'react-router-dom';
import Banner from '../../components/constants/banner'
import Facebook from '../../images/facebook-large.png';
import Instagram from '../../images/instagram-large.png';
import Twitter from '../../images/twitter-large.png';

class Hello extends React.Component {
    render(){
        return (
            <div className="mainContent">
                <section className='banner banneroverlay bannercrop' title="Banner image">
                    <Banner text={'News Article'} />
                </section>
                <div className="newsBlock">
                    <div className="newsItem">
                        <div className="newsTitle">Hello world!</div>
                        <div className="newsDate">Posted on: October 23, 2020</div>
                        <div className="newsText">
                            <p>Hello! We are very excited to get up and running. Everything is looking a little empty right now, but it is all going to start filling up quickly as we get underway and start working on our first projects!</p>
                            <br/>
                            <p>Please follow us either here on the website or through one of our social media channels to keep up with what we're doing and get involved.</p>
                            <br/>
                            <p><Link to="/shinealight" className="campaignLink">Have a look at the campaign page and find out what it's all about</Link></p>
                            <br/><br/>
                            <p>See you soon <span role="img" aria-label="Smiling face emoji">🙂</span></p>
                        </div>
                        <div className="newsImage">
                        <div className="socialcards">
                            <div className='socialcard'>
                                <a href="https://www.facebook.com/cmarcuk" rel="noopener noreferrer">
                                    <div className="socialcardImage"><img src={Facebook} alt="Facebook" /></div>
                                    <div className="socialcardTitle">Facebook</div>
                                    <div className="socialcardText">/cmarcuk</div>
                                </a>
                            </div>
                            <div className='socialcard'>
                                <a href="https://www.instagram.com/cmarcuk" rel="noopener noreferrer">
                                    <div className="socialcardImage"><img src={Instagram} alt="Instagram" /></div>
                                    <div className="socialcardTitle">Instagram</div>
                                    <div className="socialcardText">@cmarcuk</div>
                                </a>
                            </div>
                            <div className='socialcard'>
                                <a href="https://www.twitter.com/cmarcuk" rel="noopener noreferrer">
                                    <div className="socialcardImage"><img src={Twitter} alt="Twitter" /></div>
                                    <div className="socialcardTitle">Twitter</div>
                                    <div className="socialcardText">@cmarcuk</div>
                                </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="newsSide">
                        <div className="newsSideHeading">Recent Posts</div>
                        <div className="newsSideItems">
                            <div className="newsSideItem"><a href="/news-12days">12 Days of Christmas</a></div>
                            <div className="newsSideItem"><a href="/news-shine">Shine a Light on Litter</a></div>
                            <div className="newsSideItem"><a href="/news-hello">Hello world!</a></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Hello;