import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Logo from '../../images/cmarc-logo.png';

class Header extends Component {
  constructor(){
    super();
    this.state={
        mobilemenu: false,
    };
    this.hideComponent = this.hideComponent.bind(this);
}

hideComponent(varname){
  switch (varname)
  {
      case "mobilemenu":
          this.setState({mobilemenu: !this.state.mobilemenu});
          break;
      default: return;
  }
}

  _handleClick(e) { 
    var fileName = window.location.href.split("/").slice(-1)[0]; 
    if (fileName === "" || fileName === null)
    {
      fileName = "home";
    }
    if (fileName === e.target.innerHTML.toLowerCase()) {
      document.querySelectorAll(".menulink").forEach(el => {el.classList.remove("menucurrent")})
      e.target.parentElement.classList.add("menucurrent")
    }
  }

  render() {
    const {mobilemenu} = this.state;

    return (
      <header>
          <div id="header">
            <div id="headerLeft">
              <div id="siteLogo"><a href="/" title="Home"><img src={Logo} alt="Christchurch Marine Conservation logo" /></a></div>
              <div id="siteName">
                <div id="siteAbbr">CMarC</div>
                <div id="siteFull">Christchurch Marine Conservation</div>
              </div>
            </div>
            <div id="headerMid">
              <div id="siteMenu">
                <nav>
                  <ul id="siteNav" title="Site navigation">
                    <li className="menulink" onClick={this._handleClick}><Link to='/' >Home</Link></li>
                    <li className="menulink" onClick={this._handleClick}><Link to='/events'>Events</Link></li>
                    <li className="menulink" onClick={this._handleClick}><Link to='/campaigns'>Campaigns</Link></li>
                    <li className="menulink"onClick={this._handleClick}><Link to='/news'>News</Link></li>
                    <li className="menulink"onClick={this._handleClick}><Link to='/contact'>Contact</Link></li>
                    <li className="menulink"onClick={this._handleClick}><Link to='/team'>Team</Link></li>
                    <li className="menulink"onClick={this._handleClick}><Link to='/about'>About</Link></li>
                  </ul>
                </nav>
              </div>
                {!mobilemenu && <div id="menuClick" onClick={() => this.hideComponent("mobilemenu")}><span id="siteMenuMobile"></span></div>}
                {mobilemenu && <div id="menuClick" onClick={() => this.hideComponent("mobilemenu")}><span id="siteMenuMobile" className="active" onClick={() => this.hideComponent("mobilemenu")}></span></div>}
            </div>
          </div>
          {mobilemenu && <nav id="mobileMenu">
            <ul id="siteNavMobile" title="Mobile navigation">
              <li className="menulink" onClick={this._handleClick}><Link to='/' >Home</Link></li>
              <li className="menulink" onClick={this._handleClick}><Link to='/events'>Events</Link></li>
              <li className="menulink" onClick={this._handleClick}><Link to='/campaigns'>Campaigns</Link></li>
              <li className="menulink"onClick={this._handleClick}><Link to='/news'>News</Link></li>
              <li className="menulink"onClick={this._handleClick}><Link to='/contact'>Contact</Link></li>
              <li className="menulink"onClick={this._handleClick}><Link to='/team'>Team</Link></li>
              <li className="menulink"onClick={this._handleClick}><Link to='/about'>About</Link></li>
            </ul>
          </nav>
          }
      </header>
    )
  }
}
export default Header;

