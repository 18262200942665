import React, { Component } from 'react';
import Facebook from '../../images/icon-facebook-32.png';
import Twitter from '../../images/icon-twitter-32.png';
import Instagram from '../../images/icon-instagram-32.png';
 
class Footer extends Component {
  render() {
    return (
        <footer>
          <div id="footer">
            <div id="footerLeft">&copy; 2022 Christchurch Marine Conservation</div>
            <div id="footerRight">
              <ul title="Quick links">
                <li className="social-icon"><a href="https://www.facebook.com/cmarcuk" rel="noopener noreferrer"><img src={Facebook} alt="Facebook" /></a></li>
                <li className="social-icon"><a href="https://www.twitter.com/cmarcuk" rel="noopener noreferrer"><img src={Twitter} alt="Twitter" /></a></li>
                <li className="social-icon"><a href="https://www.instagram.com/cmarcuk" rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a></li>
                <li><a href="#main">Jump to top</a></li>
              </ul></div>
          </div>
        </footer>
    );
  }
}
 
export default Footer;


