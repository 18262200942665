import React from 'react';
import {Link} from 'react-router-dom';
import Banner from '../components/constants/banner'
import Shine from '../images/shinealight.png'

class Campaigns extends React.Component {
    render(){
        return (
            <div className = "homeContent">
                <section className='banner banneroverlay bannercrop' title="Banner image">
                    <Banner text={'Campaigns'} />
                </section>
                <section title="Main page content" id="mainContent">
                You will find here a list of the campaigns that we are currently running or that we are involved with. Although it may look a little empty at the moment, it will fill up quickly as we get going and will be updated regularly.
                <br/><br/>
                We are very happy to be able to share our first campaign with you:
                <br/><br/>
                <div className="campaigns">
                    <div className = "campaign">
                        <div className="campaignImage">
                            <img src={Shine} alt="Shine a light on litter" />
                        </div>
                        <div className="campaignContent">
                            <div className="campaignTitle"><Link to="/shinealight">Shine a Light on Litter</Link></div>
                            <br/>
                            <div className="campaignText">
                                Join our virtual litter picking team and find other people litter picking their local areas. Connect, share and collect data. There are two ways to get involved:
                                <br/><br/>
                                <span className="strong">Snap and Share</span> or <span className="strong">Snap and Tag</span>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            </div>
        )
    }
}

export default Campaigns;