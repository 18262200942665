import React from 'react';
import {Routes, Route} from 'react-router-dom';
import './styles/styles.css';

import Header from './components/constants/header';
import Footer from './components/constants/footer';
import Home from './pages/home';
import Events from './pages/events';
import Campaigns from './pages/campaigns';
import News from './pages/news';
import Contact from './pages/contact';
import Team from './pages/team';
import About from './pages/about';
import Shine from './pages/campaigns/shinealight';
import TwelveDays from './pages/news/12days';
import ShineNews from './pages/news/shine';
import Hello from './pages/news/hello';
import TwelveDaysActivities from './pages/campaigns/twelvedaysactivities';

function App() {
  return (
    <div>
      <Header />
      <main>
        <Routes>
          <Route path = "/" exact element={<Home />} />
          <Route path = "/events" element={<Events />} />
          <Route path = "/campaigns" element={<Campaigns />} />
          <Route path = "/news" element={<News />} />
          <Route path = "/contact" element={<Contact />} />
          <Route path = "/team" element={<Team />} />
          <Route path = "/about" element={<About />} />
          <Route path = "/shinealight" element={<Shine />} />
          <Route path = "/news-12days" element={<TwelveDays />} />
          <Route path = "/news-shine" element={<ShineNews />} />
          <Route path = "/news-hello" element={<Hello />} />
          <Route path = "/12days" element={<TwelveDaysActivities />} />
        </Routes>
      </main>
      <Footer />
    </div>
);
}

export default App;

