import React from 'react';
import {Link} from 'react-router-dom';
import Banner from '../components/constants/banner'
import HelloImage from '../images/cmarc-banner.jpg'
import ShineImage from '../images/shinealight.png'

class News extends React.Component {
    render(){
        return (

            <div className = "newsContent">
                <section className='banner banneroverlay bannercrop' title="Banner image">
                    <Banner text={'News'} />
                </section>
                <section title="Main page content" id="mainContent" className="text-center">
                Get up to date with the latest news and scroll through the archives to see what we have been doing to promote marine conservation.
                    <br/>
                    <br/>
                    <div id="newsArchive">
                        <div id="buttonNews">Go to the news archive</div>
                    </div>

                    <div id="newsarticles">
                        <div className="article">
                            <Link to="/news-12days">
                                <div className='articleImage'></div>
                                <div className='articleName'>12 Days of Christmas</div>
                                <div className='articleDate'>December 24, 2020</div>
                            </Link>
                        </div>
                        <div className="article">
                            <Link to="/news-shine">
                            <div className='articleImage'><img src={ShineImage} alt="Shine a light on litter" /></div>
                                <div className='articleName'>Shine a Light on Litter</div>
                                <div className='articleDate'>December 18, 2020</div>
                            </Link>
                        </div>
                        <div className="article">
                            <Link to="/news-hello">
                                <div className='articleImage'><img src={HelloImage} alt="Fisheye view of the water surface" /></div>
                                <div className='articleName'>Hello World!</div>
                                <div className='articleDate'>October 23, 2020</div>
                            </Link>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default News;