import React from 'react';
import Banner from '../components/constants/banner'

class About extends React.Component {
    render(){
        return (
            <div className="eventsContent">
                <section className='banner banneroverlay bannercrop' title="Banner image">
                    <Banner text={'About Us'} />
                </section>
                <section title="Main page content" id="mainContent">
                    CMarC was started by a team of two, passionate about helping to save the planet. Having met at a beach clean event, we were chatting and it became clear that we could join forces and work together to learn, educate and promote conservation in our local area.
                    <br/><br/>
                    One of us has now moved on to other things and so currently we are a “Team” of one and things are on hold whilst we evaluate the future of CMarC and the direction it should take.
                    <br/><br/>
                    <hr className="separator-about" />
                    We are making Mudeford Quay our home base so you will frequently find us there where we are always happy to chat and answer questions and will be holding regular events (as far as Covid-19 regulations currently allow!)
                    <br/><br/>
                    We're not sure we can adequately lay out our mission yet, because we are still talking about and deciding on what projects and campaigns to pursue but we will be posting regular updates and asking everyone to get involved.
                    <br/><br/>
                    The website and our social media will adapt and change as we grow so please do follow us on your preferred channel to see how things start to develop.
                    <br/><br/>
                    There is so much good that communities can do when they come together and we live in a very beautiful area that needs to be protected.
                    <br/><br/>
                    We are aware that we are not the only people who care about the local area and we acknowledge that we are not experts, but we have knowledge and understanding of conservation topics and plenty of enthusiasm! If you have something you would like to share, to teach us, to show us, please get in touch, we’d love to hear from you and will gladly engage, listen and share.
                    <br/><br/>
                    Come and join our journey into conservation and learn along with us. Knowledge is always better shared.
                    <br/><br/>
                </section>
            </div>
        )
    }
}

export default About;