import React from 'react';
import Banner from '../../components/constants/banner'
import Shine from '../../images/shinealight.png'

class ShineALight extends React.Component {
    render(){
        return (
            <div className = "homeContent">
                <section className='banner banneroverlay bannercrop' title="Banner image">
                    <Banner text={'Shine a Light on Litter'} />
                </section>
                <section title="Main page content" id="mainContent">
                <p><a href="#getinvolved" className="campaignLink">Jump to “Get Involved”!</a></p>
                <br/>
                <p>During the pandemic, all our regular litter picking groups have been shut down because of the restrictions on the number of people who can meet outdoors.</p>
                <br/>
                <p>We have noticed that people do still pick up when they are out walking and, recently, people have started sharing photos and details of what they have been picking up to local social media groups.</p>
                <br/>
                <p>So, we thought we would work with this and create a virtual litter picking group, so that everyone who does this individually has a place where they can share their outings, findings and thoughts with everyone else who is doing the same. Bringing like minded people together in one place.</p>
                <br/>
                <p>This could lead to particular hotspots or problem items being highlighted and help to create an awareness of specific issues. When we collect results from lots of different people and places, it becomes much easier to track the problem of litter as a whole.</p>
                <br/>
                <p>Our beaches get a lot of love locally, so we are very keen for this to be open to everyone in every open space.</p>
                <br/>
                <p>You can litter pick your street, local park, a riverbank, fields, car parks, playgrounds and of course the beaches – anywhere you go. Let’s show everywhere some love!</p>
                <br/><br/>
                <div className="campaignImageBlock"><img src={Shine} alt="Shine a light on litter" /></div>
                <br/><br/>
                <p className="campaign-highlight" id="getinvolved">There are two ways to get involved:</p>
                <br/><br/>
                <div className="campaignHeadingBlock">Snap and Share</div>
                <div className="campaignTextBlock">
                    <p>Most of us carry smart phones with us these days, or a camera.</p>
                    <br/>
                    <p>Snap photos of your litter pick, or your bag/bucket at the end of your trip, share it either on your social media and tag us, or share to our social media pages.</p>
                    <br/>
                    <p>Add our poster image to your post to help us spread the message and use the hashtag #shinealightonlitter</p>
                    <br/>
                    <p><a href={Shine} target="_blank" rel="noopener noreferrer">Click here to download the image</a></p>
                </div>
                <div className="campaignHeadingBlock">Snap and Tag</div>
                <div className="campaignTextBlock">
                    <p>We hope that you might like to take it a step further and help us collect litter data.</p>
                    <br/>
                    <p>We have set up a team on OpenLitterMap that anyone can join. Tag your litter and see how far you can get on the leaderboard – a little friendly competition to help with vital information gathering!</p>
                    <br/>
                    <p>You will need to create an account at <a href="https://openlittermap.com/" target="_blank" rel="noopener noreferrer">https://openlittermap.com/</a></p>
                    <br/>
                    <p>It doesn’t cost anything to join and once you have signed up you can join the team from your account page.</p>
                    <br/>
                    <p>We will share the leaderboard on social media from time to time, so please choose an appropriate display name!</p>
                    <br/>
                    <p><a href="https://www.christchurchmarineconservation.org.uk/campaigns/openlittermap/" taget="_blank" rel="noopener noreferrer">Click here for more information on OpenLitterMap.</a></p>
                    <br/><br/>
                    <p className="strong">Please use the poster on any posts you share to help us spread the word and use the hashtags #shinealightonlitter #teamcmarcuk</p>
                </div>
                </section>
            </div>
        )
    }
}

export default ShineALight;