import React from 'react';
import {Link} from 'react-router-dom';
import Banner from '../../components/constants/banner'
import Shine from '../../images/shinealight.png'

class NewsShine extends React.Component {
    render(){
        return (
            <div className = "mainContent">
                <section className='banner banneroverlay bannercrop' title="Banner image">
                    <Banner text={'News Article'} />
                </section>
                <div className="newsBlock">
                    <div className="newsItem">
                        <div className="newsTitle">Shine a Light on Litter</div>
                        <div className="newsDate">Posted on: December 18, 2020</div>
                        <div className="newsText">
                            <p>Back in November, we released our "Shine a Light on Litter" campaign to try and bring litter pickers together virtually during coronavirus times.</p>
                            <br/>
                            <p><Link to="/shinealight" className="campaignLink">Have a look at the campaign page and find out what it's all about</Link></p>
                            <br/><br/>
                        </div>
                        <div className="newsImage">
                            <img src={Shine} alt="Shine a Light on Litter" />
                        </div>
                    </div>
                    <div className="newsSide">
                        <div className="newsSideHeading">Recent Posts</div>
                        <div className="newsSideItems">
                            <div className="newsSideItem"><a href="/news-12days">12 Days of Christmas</a></div>
                            <div className="newsSideItem"><a href="/news-shine">Shine a Light on Litter</a></div>
                            <div className="newsSideItem"><a href="/news-hello">Hello world!</a></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsShine;