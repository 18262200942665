import React from 'react';
import Banner from '../components/constants/banner'
import Amelia from '../images/amelia.jpg'

class Team extends React.Component {
    render(){
        return (
        <div className = "eventsContent">
            <section className='banner banneroverlay bannercrop' title="Banner image">
                <Banner text={'Meet the Team'} />
            </section>
            <section title="Main page content" id="mainContent" className="text-center">
            Meet our team below. You may have seen them pop up on other pages, they’re very busy bees at the moment!
                <br/>
                <br/>
                <div className="team">
                    <div className="teamMember">
                        <div className="teamMemberImage"><img src={Amelia} alt="Head show of Amelia looking at the camera" /></div>
                        <div className="teamMemberName">Amelia Raine</div>
                        <div className="teamMemberTitle">Team Lead</div>
                        <div className="teamMemberText">
                        Amelia has been solo beach cleaning for a few years now whilst joining and running various local beach cleans.
                        <br/><br/>
                        She doesn't profess to be an expert in all things marine conservation but is very keen to learn all about the local coastline to make sure it is protected.
                        <br/><br/>
                        Amelia holds an expert track certificate in Marine Litter and has undertaken certificated training in Conservation, Marine Biology, Living Sustainably, Nature Based Solutions and Oceanography.
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}

export default Team;