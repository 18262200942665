import React, { Component } from 'react';
import BannerImage from '../../images/cmarc-banner-1920.jpg';
import BannerImage320 from '../../images/cmarc-banner-320.jpg';
import BannerImage568 from '../../images/cmarc-banner-568.jpg';
import BannerImage768 from '../../images/cmarc-banner-768.jpg';
import BannerImage1024 from '../../images/cmarc-banner-1024.jpg';
import BannerImage1280 from '../../images/cmarc-banner-1280.jpg';
import BannerImage1366 from '../../images/cmarc-banner-1366.jpg';
import BannerImage1680 from '../../images/cmarc-banner-1680.jpg';
import BannerImage1920 from '../../images/cmarc-banner-1920.jpg';
 
class Banner extends Component {

  render() {
    const bannerTitle = this.props.text
    return (
        <section>
            <div className="bannerImage">
                <img 
                  src={BannerImage} alt="A fisheye view of the sea bed showing sea grass and the water surface." width="1536px" height="747px"
                  srcSet={`
                  ${BannerImage320} 320w,
                  ${BannerImage568} 568w,
                    ${BannerImage768} 768w,
                    ${BannerImage1024} 1024w,
                    ${BannerImage1280} 1280w,
                    ${BannerImage1366} 1366w,
                    ${BannerImage1680} 1680w,
                    ${BannerImage1920} 1920w
                  `}
                  sizes="100vw"
                />
            </div>
            <span aria-hidden="true" className="overlay"></span>
            <span className="textoverlay">{bannerTitle}</span>
        </section>
    );
  }
}
 
export default Banner;
