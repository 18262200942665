import React from 'react';
import Banner from '../components/constants/banner'

class Events extends React.Component {
    render(){
        return (
            <div className = "eventsContent">
                <section className='banner banneroverlay bannercrop' title="Banner image">
                    <Banner text={'Events'} />
                </section>
                <section title="Main page content" id="mainContent" className="text-center">
                    We don't have any events scheduled at the moment, please check back soon.
                    <br/>
                    <br/>
                </section>
            </div>
        )
    }
}

export default Events;